import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGEHEADER_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          Meta {
            Title,
            Desc,
            Img {
              data {
                attributes {
                  url
                }
              }
            }
          },
          HeaderImage {
            LightImage {
              data {
                attributes {
                  url
                }
              }
            }
            DarkImage {
              data {
                attributes {
                  url
                }
              }
            }
            Text
          },
          HeaderTitle,
          HeaderSubTitle,
          HeaderFirstCTA {
            Text,
            URL
          },
          HeaderSecondCTA {
            Text,
            URL
          }
        }
      }
    }
  }
`;

function HomePageHeaderComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <div className="relative flex min-h-screen 2xl:min-h-[1000px] dark:bg-pgray-900 bg-pwhite">
      <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4">
        <div className="relative z-20 pb-8 max-w-full lg:max-w-3xl lg:w-full">
          <Query query={HOMEPAGEHEADER_QUERY} id={null}>
            {({ data: { homepage } }) => {
              return (
                <main className="mt-10 mx-auto max-w-full lg:max-w-8xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="relative tracking-tight font-extrabold font-display dark:text-pwhite text-pgray-900 text-6xl sm:text-6xl md:text-8xl lg:text-9xl">
                      <span className="block xl:inline" >{homepage.data.attributes.HeaderTitle}</span>
                    </h1>
                    <p className="mt-3 text-4xl dark:text-pwhite text-pgray-900 opacity-60 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 sm:leading-relaxed" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.HeaderSubTitle }}></p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-full shadow">
                        <a href={homepage.data.attributes.HeaderFirstCTA.URL} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium font-sans rounded-full text-pwhite bg-pgray-900 hover:bg-pblack md:py-4 md:text-lg md:px-10 dark:border-2 border-pwhite">{homepage.data.attributes.HeaderFirstCTA.Text}</a>
                      </div>
                      <div className="mt-3 sm:mt-0 sm:ml-3">
                        <a href={homepage.data.attributes.HeaderSecondCTA.URL} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-pwhite bg-pblue-500 hover:bg-pblue-700 md:py-4 md:text-lg md:px-10">{homepage.data.attributes.HeaderSecondCTA.Text}</a>
                      </div>
                    </div>
                  </div>
                </main>
              );
            }}
          </Query>
        </div>
      </div>
      <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-3/5 backdrop-blur-md dark:bg-pgray-800 bg-pgray-50 dark:bg-opacity-50 bg-opacity-30 h-4/6 md:h-full z-10'></div>
      <div className="absolute lg:inset-y-0 lg:right-0 h-full w-full">
        <Query query={HOMEPAGEHEADER_QUERY} id={null}>
          {({ data: { homepage } }) => {
            // { console.log(homepage.data.attributes) }
            return (
              <>
                {(mode === 'dark' && homepage.data.attributes.HeaderImage.DarkImage.data.attributes.url) &&
                  <img className="w-full object-cover h-full lg:w-full lg:h-full" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.HeaderImage.DarkImage.data.attributes.url} alt="" />
                }
                {(mode === 'light' && homepage.data.attributes.HeaderImage.LightImage.data.attributes.url) &&
                  <img className="w-full object-cover h-full lg:w-full lg:h-full" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.HeaderImage.LightImage.data.attributes.url} alt="" />
                }
              </>
            );
          }}
        </Query>
      </div>
      {/* <div className="hidden lg:block absolute dark:bg-pgray-700 bg-pgray-100 z-30 w-12 h-12 -bottom-0 inset-x-[58%]">
        <div className="w-full h-full flex text-pgray-900 dark:text-pgray-100">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </div> */}
    </div>
  );
}

export default HomePageHeaderComp;