import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const NAVBAR_QUERY = gql`
  query Navbar {
    navbar {
      data {
        attributes {
          cta {
            Text,
            URL
          }
        }
      }
    }
  }
`;

function NavbarComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <Query query={NAVBAR_QUERY} id={null}>
      {({ data: { navbar } }) => {
        return (
          <div className="fixed t-0 w-full z-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex items-center py-6 md:space-x-10">
                <a className="flex justify-center space-x-4" href="htttp://parsiq.net">
                  {/* <a className="" href="htttp://parsiq.net"> */}
                  <svg className="my-auto hidden lg:block h-7 w-auto sm:h-8 dark:text-pwhite text-pgray-900" width="201" height="48" viewBox="0 0 201 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6303 32.2124V37.3371H15.7655V36.0559H22.8265V47.3119C10.9971 46.6713 1.36847 36.9711 0.726562 25.166H12.0058V32.1209L10.6303 32.2124Z" fill="currentColor" />
                    <path d="M32.4555 36.0557V37.3369H37.5908V32.2122H36.3069V25.2573H47.5862C46.9443 37.0624 37.224 46.6711 25.3945 47.3117V36.0557H32.4555Z" fill="currentColor" />
                    <path d="M71.2432 8.87646C67.4835 8.87646 64.1823 10.2492 61.8897 12.8115V9.42554H57.3047V45.5728H61.8897V32.4866C64.274 34.9574 67.5752 36.4216 71.2432 36.4216C78.9461 36.4216 84.9067 30.3818 84.9067 22.6948C84.9067 14.9163 78.9461 8.87646 71.2432 8.87646ZM71.1515 13.3606C76.2868 13.3606 80.4133 17.3871 80.4133 22.6033C80.4133 27.8194 76.3785 31.846 71.1515 31.846C66.0163 31.846 61.8897 27.8194 61.8897 22.6033C61.8897 17.3871 65.9246 13.3606 71.1515 13.3606Z" fill="currentColor" />
                    <path d="M111.134 12.72C108.75 10.2492 105.449 8.87646 101.781 8.87646C94.0778 8.87646 88.1172 14.9163 88.1172 22.6033C88.1172 30.2903 94.1695 36.33 101.781 36.33C105.449 36.33 108.75 34.9574 111.134 32.4866V35.781H115.811V9.33402H111.134V12.72ZM101.964 31.846C96.8288 31.846 92.7022 27.8194 92.7022 22.6033C92.7022 17.3871 96.7371 13.3606 101.964 13.3606C107.099 13.3606 111.134 17.3871 111.134 22.6033C111.134 27.8194 107.099 31.846 101.964 31.846Z" fill="currentColor" />
                    <path d="M134.977 9.05945H133.877C130.942 9.05945 128.558 9.97456 126.816 11.7133V9.42549H122.23V35.8724H126.816V21.322C126.816 14.1841 132.226 13.635 133.877 13.635H134.977V9.05945Z" fill="currentColor" />
                    <path d="M149.008 20.4985C145.431 19.5834 143.322 18.9428 143.322 16.838C143.322 14.4587 145.706 13.3606 148.091 13.3606C150.567 13.3606 152.584 14.4587 153.318 16.3805L153.776 17.5701L157.719 15.2823L157.352 14.3672C155.794 10.8897 152.401 8.87646 148.091 8.87646C142.68 8.87646 138.737 12.2624 138.737 16.838C138.737 22.3287 143.689 23.6099 147.724 24.708C151.3 25.6232 153.501 26.3553 153.501 28.46C153.501 31.0224 150.842 31.9375 148.274 31.9375C145.156 31.9375 142.864 30.6563 142.13 28.3685L141.672 27.0874L137.637 29.4667L137.912 30.2903C139.379 34.1338 143.139 36.4216 148.182 36.4216C153.96 36.4216 157.994 33.1272 157.994 28.46C157.994 22.7863 152.951 21.5051 149.008 20.4985Z" fill="currentColor" />
                    <path d="M168.171 9.42578H163.586V35.8727H168.171V9.42578Z" fill="currentColor" />
                    <path d="M196.142 9.42554V12.8115C193.758 10.3407 190.456 8.87646 186.788 8.87646C179.086 8.87646 173.125 14.9163 173.125 22.6033C173.125 30.2903 179.177 36.3301 186.788 36.3301C190.548 36.3301 193.849 34.9574 196.142 32.395V45.4812H200.727V9.33402H196.142V9.42554ZM186.88 31.846C181.745 31.846 177.618 27.8194 177.618 22.6033C177.618 17.3871 181.653 13.3606 186.88 13.3606C192.107 13.3606 196.142 17.3871 196.142 22.6033C196.142 27.8194 192.107 31.846 186.88 31.846Z" fill="currentColor" />
                    <path d="M169.091 0H162.672V6.40584H169.091V0Z" fill="currentColor" />
                    <path d="M22.8265 11.9882H32.5468V10.4324H37.6821V16.1062H32.5468V14.5505H22.8265V25.1659L33.8306 25.2574V32.2123H32.5468V33.4935H15.7655V32.2123H14.5734V15.5571H15.8572V10.4324H10.722V15.5571H12.0058V22.512H0.726562C1.36847 10.707 11.0888 1.09823 22.8265 0.457642V11.9882Z" fill="currentColor" />
                  </svg>
                  <svg className="my-auto lg:hidden h-7 w-auto sm:h-8 dark:text-pwhite text-pgray-900" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M10.63 32.212v5.125h5.135v-1.281h7.061v11.256c-11.829-.64-21.458-10.34-22.1-22.146h11.28v6.955l-1.376.091zM32.456 36.056v1.28h5.135v-5.124h-1.284v-6.955h11.28c-.643 11.805-10.363 21.414-22.193 22.055V36.056h7.062z"></path>
                    <path fill="currentColor" d="M22.826 11.988h9.72v-1.556h5.136v5.674h-5.135V14.55h-9.72v10.616l11.004.091v6.955h-1.284v1.281H15.766v-1.28h-1.193V15.556h1.284v-5.125h-5.135v5.125h1.284v6.955H.726C1.369 10.707 11.09 1.098 22.827.458v11.53z"></path>
                  </svg>
                  {/* </a> */}
                  <div className="border-r border-pgray-300"></div>
                  <div className="flex">
                    <div className="text-3xl font-display font-bold dark:text-pwhite text-pgray-900 opacity-60">
                      Network
                    </div>
                  </div>

                </a>

                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  {/* <a href="#" className="whitespace-nowrap text-base font-medium dark:text-phwite dark:hover:text-phwite"> Sign in </a> */}
                  {navbar.data.attributes.cta.Text &&
                    <a href={navbar.data.attributes.cta.URL} className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-xl font-medium text-pwhite bg-pblue-500 hover:bg-pblue-600">{navbar.data.attributes.cta.Text}</a>
                  }
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
}

export default NavbarComp;