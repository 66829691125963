import { useState, useEffect } from "react";

import './styles/tailwind.css';

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./utils/apolloClient";

import Navbar from './comp/NavbarComp';
import { FooterComp } from './comp/FooterComp';

import MediumComp from './comp/MediumComp';
import HomePageHeaderComp from './comp/HomePageHeaderComp';
import HomePageIntroductionComp from './comp/HomePageIntroductionComp';
import HomePageFeaturesComp from './comp/HomePageFeaturesComp';
import HomePageOutroductionComp from './comp/HomePageOutroductionComp';
import HomePageNewsletterSignupComp from './comp/HomePageNewsletterSignupComp';
import MediumLineComp from './comp/MediumLineComp';

function App() {
  const [mode, setMode] = useState(localStorage.theme ? localStorage.theme : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

  const onSelectMode = (mode) => {
    setMode(mode)
    if (mode === 'dark')
      document.body.classList.add('dark')
    else
      document.body.classList.remove('dark')

    if (mode === 'dark')
      localStorage.theme = 'dark'
    else
      localStorage.theme = 'light'
  }

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

    if (localStorage.theme)
      onSelectMode(localStorage.theme === 'dark' ? 'dark' : 'light')
    else
      onSelectMode((window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'))

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  return (
    <div className="relative dark:bg-pgray-900 bg-pwhite dark:selection:text-pwhite dark:selection:bg-pblue-500 overflow-hidden">
      <ApolloProvider client={client}>
        <Navbar mode={mode} />

        <HomePageHeaderComp mode={mode} />
        <MediumLineComp mode={mode} />
        <HomePageIntroductionComp mode={mode} />
        <HomePageFeaturesComp mode={mode} />
        <HomePageOutroductionComp mode={mode} />
        <HomePageNewsletterSignupComp mode={mode} />
        <MediumComp mode={mode} />

        <FooterComp mode={mode} Changemode={(mode) => onSelectMode(mode)} />

      </ApolloProvider>

    </div>
  );
}

export default App;
