import React, { Component, useState } from "react";
import axios from "axios";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGENEWSLETTER_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          Newsletter {
            Title,
            Title2,
            Desc
          }
        }
      } 
    }
  }
`;

class NewsletterSignupComp extends Component {
  render() {
    return (
      <>
        <NewsletterForm />
      </>
    )

    function NewsletterForm(props) {
      const [email, setEmail] = useState('');

      const handleSubmit = async (e) => {
        // e.preventDefault();

        if (!email) {
          alert('Please enter your email.');
        } else {
          const portalId = '8941517';
          const formGuid = '046e9ca6-59ea-4a6f-9a92-9f23d7184f12';
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
          const response = await axios.post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
            {
              portalId,
              formGuid,
              fields: [
                {
                  name: 'email',
                  value: email,
                },
              ],
            },
            config
          );
          console.log(response);
          // console.log('test')
          return response;
        }
      };

      return (
        <Query query={HOMEPAGENEWSLETTER_QUERY} id={null}>
          {({ data: { homepage } }) => {
            return (
              <div className="dark:bg-pgray-800 bg-pwhite flex">
                <div className="w-full">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-6">
                    <div className="bg-pgray-50 dark:bg-pgray-800 rounded-xl p-8 sm:p-16 text-center overflow-hidden relative">
                      <div className="text-4xl sm:text-8xl font-bold dark:text-pwhite text-pgray-800 mb-0 z-10" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Newsletter.Title }}></div>
                      <div className="text-3xl sm:text-6xl font-bold dark:text-pwhite text-pgray-800 mb-5 leading-tight z-10" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Newsletter.Title2 }}></div>
                      <div className="text-xl sm:text-4xl dark:text-pwhite opacity-60 text-pgray-800 mb-6 leading-tight z-10" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Newsletter.Desc }}></div>
                      <div className="my-auto w-full">
                        <form className="max-w-2xl mx-auto">
                          <div className="flex flex-col sm:flex-row w-full space-y-3 sm:space-x-3 sm:space-y-0">
                            <div className="w-full relative rounded-md shadow-sm">
                              <input
                                type='email'
                                className="px-6 py-4 border-1 focus:ring-2 focus:ring-pred-600 block w-full text-xl rounded-md placeholder-pgray-500 text-pgray-700 bg-pgray-200 dark:bg-pgray-700"
                                placeholder="john.smith@company.com"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            <button className="whitespace-nowrap inline-flex items-center justify-center px-6 py-4 border-transparent rounded-md text-xl text-pwhite font-medium bg-pblue-500 hover:bg-pblue-700 hover:text-pwhite hover:no-underline" onClick={() => handleSubmit()} >
                              Subscribe
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>


      );
    }

  }
}

export default NewsletterSignupComp;
