import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGEOUTRODUCTION_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          Outroduction1,
          Outroduction2
        }
      }
    }
  }
`;

function HomePageOutroductionComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <Query query={HOMEPAGEOUTRODUCTION_QUERY} id={null}>
      {({ data: { homepage } }) => {
        return (
          <div className="dark:bg-pgray-900 bg-pwhite flex">
            <div className="w-full pt-16 pb-8 sm:py-32">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mb-16">
                <div className="">
                  <div className="text-4xl sm:text-8xl dark:text-pwhite text-pgray-800 mb-5 leading-tight mb-10" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Outroduction1 }}></div>
                  <div className="text-3xl sm:text-6xl dark:text-pwhite text-pgray-800 mb-5 leading-tight" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Outroduction2 }}></div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
}

export default HomePageOutroductionComp;