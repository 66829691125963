import React, { useState } from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const FOOTER_QUERY = gql`
  query Footer {
    footer {
      data {
        attributes {
          Copyright,
          SocialLinks {
            Text,
            URL
          }
        }
      }
    }
  }
`;

function renderSwitch(param) {
  switch (param) {
    case 'telegram':
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" /></svg>;
    case 'medium':
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13.54 12a6.8 6.8 0 01-6.77 6.82A6.8 6.8 0 010 12a6.8 6.8 0 016.77-6.82A6.8 6.8 0 0113.54 12zM20.96 12c0 3.54-1.51 6.42-3.38 6.42-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42 3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z" /></svg>;
    case 'discord':
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z" /></svg>;
    case 'twitter':
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>;
    case 'linkedin':
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" /></svg>;
    default:
      return <svg className="fill-current cursor-pointer dark:text-pgray-600 text-pgray-700 hover:text-pblue-500" width="100%" height="100%" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"></svg>;
  }
}

const FooterComp = (props) => {
  const mode = props.mode ? props.mode : "light";

  return (
    <div className="relative w-full dark:bg-pgray-900 bg-pwhite mt-16">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 border-t dark:border-pgray-800 border-pgray-100">
        <div className="md:flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex flex-col sm:flex-row justify-start lg:w-0 lg:flex-1 space-y-3 sm:space-x-3 space-y-0">
            <a href="">
              <svg className="h-6 w-auto sm:h-6 dark:text-pwhite text-pgray-900" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M10.63 32.212v5.125h5.135v-1.281h7.061v11.256c-11.829-.64-21.458-10.34-22.1-22.146h11.28v6.955l-1.376.091zM32.456 36.056v1.28h5.135v-5.124h-1.284v-6.955h11.28c-.643 11.805-10.363 21.414-22.193 22.055V36.056h7.062z"></path>
                <path fill="currentColor" d="M22.826 11.988h9.72v-1.556h5.136v5.674h-5.135V14.55h-9.72v10.616l11.004.091v6.955h-1.284v1.281H15.766v-1.28h-1.193V15.556h1.284v-5.125h-5.135v5.125h1.284v6.955H.726C1.369 10.707 11.09 1.098 22.827.458v11.53z"></path>
              </svg>
            </a>
            <Query query={FOOTER_QUERY} id={null}>
              {({ data: { footer } }) => {
                return (
                  <div className="text-base text-pgray-900 dark:text-pwhite"><span className="opacity-60">{footer.data.attributes.Copyright} | </span><a href="mailto:info@parsiq.net" className="hover:opacity-100 opacity-60" rel="noopener noreferrer">info@parsiq.net</a></div>
                );
              }}
            </Query>
          </div>

          <Query query={FOOTER_QUERY} id={null}>
            {({ data: { footer } }) => {
              // {console.log(footerSocial)}
              return (
                <div className="mt-3 sm:mt-0 sm:mx-3 md:flex md:flex-row-reverse flex-row flex">
                  {footer.data.attributes.SocialLinks.map((sociallink, i) =>
                    <a key={i} href={sociallink.URL} className="h-6 mx-1" target="_blank" rel="noopener noreferrer">
                      {renderSwitch(sociallink.Text)}
                    </a>
                  )}
                </div>
              );
            }}
          </Query>

          <div className="text-pgray-500" onClick={() => props.Changemode(mode == "light" ? "dark" : "light")}>
            {mode === 'dark' &&
              <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current cursor-pointer" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z" ></path>
                <path d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z" ></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z" ></path>
              </svg>
            }
            {mode === 'light' &&
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export { FooterComp };