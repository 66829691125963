import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGEFEATURES_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          Features {
            Image{
            	LightImage {
              	data {
                  attributes {
                    url
                  }
                }
              },
              DarkImage {
              	data {
                  attributes {
                    url
                  }
                }
            	}
            },
            Title,
            Desc,
            Feat{
              Desc
            }
          }
        }
      }
    }
  }
`;

function HomePageFeaturesComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <Query query={HOMEPAGEFEATURES_QUERY} id={null}>
      {({ data: { homepage } }) => {
        return (
          <>
            <div className="dark:bg-pgray-900 bg-pwhite flex">
              <div className="w-full pt-16 pb-8 sm:pt-32 sm:pb-32">
                <div className="max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-x-8 sm:gap-y-32 px-4 sm:px-6 lg:px-6">
                  <div className="order-1 md:order-1">
                    {(mode === 'dark' && homepage.data.attributes.Features[0].Image.DarkImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[0].Image.DarkImage.data.attributes.url} alt="" />
                    }
                    {(mode === 'light' && homepage.data.attributes.Features[0].Image.LightImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[0].Image.LightImage.data.attributes.url} alt="" />
                    }
                  </div>

                  <div className="order-2 md:order-2 w-full lg:w-full lg:h-full dark:text-pwhite text-pgray-800 text-xl">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="p-0 sm:p-8 rounded-xl col-span-1 sm:col-span-2">
                        <div className="rounded-lg bg-pblue-500/20 group-hover:bg-pblue-500 w-10 h-10 flex items-center justify-center text-pblue-500 group-hover:text-pwhite mr-2 group-hover:shadow-2xl mb-4">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                          </svg>
                        </div>
                        <p className="text-2xl mb-3">
                          {homepage.data.attributes.Features[0].Title}
                        </p>
                        <p className="opacity-60 leading-normal mb-5" 
                          dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Features[0].Desc }}>
                        </p>
                      </div>

                      {homepage.data.attributes.Features[0].Feat && homepage.data.attributes.Features[0].Feat.map((tag, i) => (
                        <div key={i} className="p-4 sm:p-8 rounded-xl dark:bg-pgray-800 bg-pgray-50 flex flex-col gap-3 ">
                          <div className="w-8 h-8 text-pgreen-500">
                            <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="12" fill="currentColor" opacity="0.1"></circle>
                              <path d="M7 13l3 3 7-7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </div>
                          <div className="w-full "> 
                            {tag.Desc}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="order-4 md:order-3 w-full lg:w-full lg:h-full dark:text-pwhite text-pgray-800 text-xl">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="p-0 sm:p-8 rounded-xl col-span-1 sm:col-span-2">
                        <div className="rounded-lg bg-pblue-500/20 group-hover:bg-pblue-500 w-10 h-10 flex items-center justify-center text-pblue-500 group-hover:text-pwhite mr-2 group-hover:shadow-2xl mb-4">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <p className="text-2xl mb-3">
                          {homepage.data.attributes.Features[1].Title}
                        </p>
                        <p className="opacity-60 leading-normal mb-5" 
                          dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Features[1].Desc }}>
                        </p>
                      </div>
                      {homepage.data.attributes.Features[1].Feat && homepage.data.attributes.Features[1].Feat.map((tag, i) => (
                        <div key={i} className="p-4 sm:p-8 rounded-xl dark:bg-pgray-800 bg-pgray-50 flex flex-col gap-3 ">
                          <div className="w-8 h-8 text-pgreen-500">
                            <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="12" fill="currentColor" opacity="0.1"></circle>
                              <path d="M7 13l3 3 7-7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </div>
                          <div className="w-full "> 
                            {tag.Desc}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="order-3 md:order-4">
                    {(mode === 'dark' && homepage.data.attributes.Features[1].Image.DarkImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[1].Image.DarkImage.data.attributes.url} alt="" />
                    }
                    {(mode === 'light' && homepage.data.attributes.Features[1].Image.LightImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[1].Image.LightImage.data.attributes.url} alt="" />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="dark:bg-pgray-900 bg-pwhite flex">
              <div className="w-full pb-8 sm:pb-32">
                <div className="max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-x-8 sm:gap-y-32 px-4 sm:px-6 lg:px-6">
                  <div className="order-1 md:order-1 dark:bg-pgray-700 bg-pgray-50 rounded-xl">
                    {(mode === 'dark' && homepage.data.attributes.Features[2].Image.DarkImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[2].Image.DarkImage.data.attributes.url} alt="" />
                    }
                    {(mode === 'light' && homepage.data.attributes.Features[2].Image.LightImage.data.attributes.url) &&
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-xl" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[2].Image.LightImage.data.attributes.url} alt="" />
                    }
                  </div>
                  <div className="p-0 sm:p-8 order-2 md:order-2 w-full lg:w-full lg:h-full rounded-xl dark:text-pwhite text-pgray-800 text-xl flex flex-col justify-center">
                    <div className="rounded-lg bg-pblue-500/20 group-hover:bg-pblue-500 w-10 h-10 flex items-center justify-center text-pblue-500 group-hover:text-pwhite mr-2 group-hover:shadow-2xl mb-4 mt-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                      </svg>
                    </div>
                    <p className="text-2xl mb-3">
                      {homepage.data.attributes.Features[2].Title}
                    </p>
                    <p className="opacity-60 leading-normal mb-5" 
                      dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Features[2].Desc }}>
                    </p>
                  </div>

                  <div className="order-4 md:order-3 w-full lg:w-full lg:h-full dark:text-pwhite text-pgray-800 text-xl">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="p-0 sm:p-8 rounded-xl col-span-1 sm:col-span-2">
                        <div className="rounded-lg bg-pblue-500/20 group-hover:bg-pblue-500 w-10 h-10 flex items-center justify-center text-pblue-500 group-hover:text-pwhite mr-2 group-hover:shadow-2xl mb-4">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                        <p className="text-2xl mb-3">
                          {homepage.data.attributes.Features[3].Title}
                        </p>
                        <p className="opacity-60 leading-normal mb-5" 
                          dangerouslySetInnerHTML={{ __html: homepage.data.attributes.Features[3].Desc }}>
                        </p>
                      </div>
                      {homepage.data.attributes.Features[3].Feat && homepage.data.attributes.Features[3].Feat.map((tag, i) => (
                        <div key={i} className="p-4 sm:p-8 rounded-xl dark:bg-pgray-800 bg-pgray-50 flex flex-col gap-3 ">
                          <div className="w-8 h-8 text-pgreen-500">
                            <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none">
                              <circle cx="12" cy="12" r="12" fill="currentColor" opacity="0.1"></circle>
                              <path d="M7 13l3 3 7-7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </div>
                          <div className="w-full "> 
                            {tag.Desc}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="order-3 md:order-4 dark:bg-pgray-700 bg-pgray-50 rounded-xl flex items-center justify-center">
                    <img className="w-full object-cover lg:w-full rounded-xl p-20" src={process.env.REACT_APP_BACKEND_URL + homepage.data.attributes.Features[3].Image.LightImage.data.attributes.url} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Query>
  );
}

export default HomePageFeaturesComp;