import React, { Component } from "react";
import axios from "axios";
import gql from "graphql-tag";
import Query from "../utils/Query";

const HOMEPAGENEWS_QUERY = gql`
  query HomePage {
    homepage {
      data {
        attributes {
          News {
            Titile,
            Desc
          }
        }
      } 
    }
  }
`;

class MediumBlogPostListComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@parsiq").then(posts => {
      // console.log(posts.data);
      const dataarr = [];
      posts.data.items.map((post, i) => (
        i < 6 && dataarr.push([post.title, post.guid, post.pubDate, post.thumbnail])
      ))
      this.setState({
        posts: dataarr
      });
    });
  }

  formatDate(e) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const current_datetime = new Date(e)
    const formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + ", " + current_datetime.getFullYear()
    return formatted_date;
  }


  render() {
    return (
      <Query query={HOMEPAGENEWS_QUERY} id={null}>
        {({ data: { homepage } }) => {
          return (
            this.state.posts != [] && <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 pt-32">
              {/* {console.log(this.state.posts)} */}
              <div className="max-w-7xl mx-auto px-0 sm:px-4 sm:px-6 lg:px-6">
                <div className="text-5xl sm:text-7xl dark:text-pwhite text-pgray-800 mb-5" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.News.Titile }}></div>
                <div className="text-4xl sm:text-6xl dark:text-pwhite opacity-60 text-pgray-800 mb-8 sm:mb-16" dangerouslySetInnerHTML={{ __html: homepage.data.attributes.News.Desc }}></div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-8 ">
                {this.state.posts && this.state.posts.map((post, i) => (
                  <a href={post[1]} key={i} className="group dark:bg-pgray-800 bg-pgray-50 dark:hover:bg-pgray-700 hover:bg-pgray-100 rounded-xl">
                    <div className="">
                      <img className="h-48 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-t-xl" src={post[3]} alt="" />
                    </div>
                    <div className="p-4">
                      <div className="">
                        <div className="">
                          <div className="font-bold dark:text-pwhite text-pgray-900">
                            <span dangerouslySetInnerHTML={{ __html: post[0] }} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 dark:text-pwhite text-pgray-900 group-hover:opacity-70 opacity-50">
                        {this.formatDate(post[2])}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default MediumBlogPostListComp;
